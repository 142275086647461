export default class UtilityController {
    constructor() {
        let controllerName = "ES6 - UtilityController loaded"
        console.log(controllerName)
    }
    debounce(func, wait, immediate) {
      var timeout;

      // This is the function that is actually executed when
      // the DOM event is triggered.
      return function executedFunction() {
        // Store the context of this and any
        // parameters passed to executedFunction
        var context = this;
        var args = arguments;

        // The function to be called after
        // the debounce time has elapsed
        var later = function() {
          // null timeout to indicate the debounce ended
          timeout = null;

          // Call function now if you did not on the leading end
          if (!immediate) func.apply(context, args);
        };

        // Determine if you should call the function
        // on the leading or trail end
        var callNow = immediate && !timeout;

        // This will reset the waiting every function execution.
        // This is the step that prevents the function from
        // being executed because it will never reach the
        // inside of the previous setTimeout
        clearTimeout(timeout);

        // Restart the debounce waiting period.
        // setTimeout returns a truthy value (it differs in web vs node)
        timeout = setTimeout(later, wait);

        // Call immediately if you're dong a leading
        // end execution
        if (callNow) func.apply(context, args);
      };
    }

}
