import UtilityController from './controller/utility_controller.js';
const utils = new UtilityController();

const projectborder = 5;
const breakpoint1 = 600;
let useKeyboardHelperShown = false;

// run code when dom is full loaded
document.addEventListener("DOMContentLoaded", function(event) {

let mainNav = document.querySelectorAll('.mainnav')[0];
let footer = document.querySelectorAll('.footer')[0];
let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
let projects = [].slice.call(document.querySelectorAll(".project"));
let projectWrapper = document.querySelectorAll(".project-wrapper");
let navInformationToggle = [].slice.call(document.querySelectorAll(".mainnav--informations, .mainnav--images"));
let navContact = document.querySelectorAll(".mainnav--contact")[0];
let overlay = document.querySelectorAll(".overlay")[0];
let overlayImage = overlay.querySelectorAll(".overlay--image")[0];
let overlayVideo = overlay.querySelectorAll(".overlay--video")[0];
let overlayBottomBar = overlay.querySelectorAll(".overlay--list")[0];
let overlayProjectTitle = overlay.querySelectorAll(".overlay--projecttitle")[0];
let overlayImageTitle = overlay.querySelectorAll(".overlay--imagetitle")[0];
let overlayProjectNumber = overlay.querySelectorAll(".overlay--projectnumber")[0];
let overlayBackground = overlay.querySelectorAll(".overlay--bg")[0];
let projectImages = [].slice.call(document.querySelectorAll(".project--image, .project--video"));
let helper = document.querySelectorAll(".helper")[0];

// lazyload images
if ("IntersectionObserver" in window) {
	let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
		entries.forEach(function(entry) {
			if (entry.isIntersecting) {
				let lazyImage = entry.target;
				lazyImage.src = lazyImage.dataset.src;
				lazyImage.srcset = lazyImage.dataset.srcset;
				lazyImage.classList.remove("lazy");
				lazyImageObserver.unobserve(lazyImage);
			}
		});
	});

	lazyImages.forEach(function(lazyImage) {
		lazyImageObserver.observe(lazyImage);
	});

} else {
	// Possibly fall back to a more compatible method here
}

// observe project images and descriptions for their height
projects.forEach(function (project) {
	let desc = project.querySelectorAll('.project--description')[0];
	let gallery = project.querySelectorAll('.project--gallery')[0];

	let checkSize = utils.debounce(function() {
		if(isHigherThan(desc, gallery) && window.innerWidth > breakpoint1){
			project.classList.add('js-height-description');
		}else{
			project.classList.remove('js-height-description');
		}
	}, 250);

	['DOMContentLoaded','load','resize','orientationchange'].forEach( function(evt) {
			window.addEventListener(evt, checkSize, false);
	});

});

// fix project numbers
projects.forEach(function (project) {
	let number = project.querySelectorAll('.project--index')[0];

	let ticking = false;

	function requestTick() {
		if(!ticking) {
			requestAnimationFrame(update);
		}
		ticking = true;
	}

	function update() {
		// reset the tick so we can
		// capture the next onScroll
		ticking = false;

		// read offset of DOM elements
		// and compare to the currentScrollY value
		// then apply some CSS classes
		// to the visible items
		let projectPos = elementInViewport(project);
		if((projectPos.topAfterViewport + projectborder) > 0 && (projectPos.bottomAfterViewport + number.offsetHeight + projectborder) < 0){
			// number.style.transform = `translateY(${projectPos.topAfterViewport}px)`;
			number.classList.add('js-sticky');
			number.style.transform = `translateY(0px)`;
		}else if((projectPos.bottomAfterViewport + number.offsetHeight + projectborder) >= 0){
			number.classList.remove('js-sticky');
			number.style.transform = `translateY(${project.offsetHeight - number.offsetHeight}px)`;
		}else{
			number.classList.remove('js-sticky');
			number.style.transform = `translateY(0px)`;
		}
	}

	['DOMContentLoaded','load','resize','orientationchange','scroll'].forEach( function(evt) {
			window.addEventListener(evt, requestTick, false);
	});

});

// Navigation

navInformationToggle.forEach(function (navElem) {
	navElem.addEventListener('click', function () {
		navInformationToggle.forEach(function (navElem) {
			navElem.classList.toggle('is-hidden')
		})
		projectWrapper[0].classList.toggle('view-information')
	});
});

navContact.addEventListener('click', function () {
	window.scrollTo(0, footer.offsetTop);
});

// Navigation Stickyfier

let ticking = false;

function requestTick() {
	if(!ticking) {
		requestAnimationFrame(update);
	}
	ticking = true;
}

function update() {
	// reset the tick so we can
	ticking = false;

	let footerPos = elementInViewport(footer);
	if((footerPos.topAfterViewportBottom - mainNav.offsetHeight)  < 0){
		mainNav.classList.add('js-sticky');
		// number.style.transform = `translateY(${footerPos.topAfterViewport}px)`;
	}else{
		mainNav.classList.remove('js-sticky')
	}
}

['DOMContentLoaded','resize','orientationchange','scroll'].forEach( function(evt) {
	window.addEventListener(evt, requestTick, false);
});

// Overlay

let projectImageInOverlay;

// get next image in array
function overlayNextImage(needle) {
	let index = projectImages.findIndex(function (elem) {
		return needle == elem;
	});
	if(projectImages[index+1]){
		return projectImages[index+1]
	}else{
		return projectImages[0]
	};
}
// get previous image in array
function overlayPrevImage(needle) {
	let index = projectImages.findIndex(function (elem) {
		return needle == elem;
	});
	if(projectImages[index-1]){
		return projectImages[index-1]
	}else{
		return projectImages[projectImages.length - 1]
	};
}
// fill the html of the overlay from the html data-attr
function fillOverlay(data, smallsrc) {
	let overlayImageImg;
	let overlayVideoElem;
	const type = data[7];
	const isSmaller = data[8];

	if(type=='video'){ // if video
		if(overlayImage.getElementsByTagName('img')[0] != undefined){
			overlayImage.style.display = 'none'
			overlayVideo.style.display = 'block'
		}
		if(overlayVideo.getElementsByTagName('video')[0] != undefined){
			// video is already existing
			overlayVideo.getElementsByTagName('video')[0].src = data[0];
			overlayVideoElem = overlayVideo.getElementsByTagName('video')[0];
		}else{
			// video is not existing
			overlayVideoElem = document.createElement("video");
			overlayVideoElem.setAttribute("loop","loop");
			overlayVideoElem.setAttribute("muted","muted");
			overlayVideoElem.setAttribute("autoplay","autoplay");
			overlayVideoElem.setAttribute("playsinline","playsinline");
			if(isSmaller == 'true'){overlayVideoElem.classList.add('smaller')}else{overlayVideoElem.classList.remove('smaller')}
			overlayVideoElem.src = data[0];
			overlayVideo.appendChild(overlayVideoElem);
			overlayVideoElem.addEventListener('click', showNextImageInGallery);
		}

		overlayImageTitle.innerHTML = data[2];
		overlayProjectTitle.innerHTML = data[1];
		overlayProjectNumber.innerHTML = data[6];

	}else{ //if image not video
		if(overlayVideo.getElementsByTagName('video')[0] != undefined){
			overlayVideo.style.display = 'none'
			overlayImage.style.display = 'block'
		}
		if(overlayImage.getElementsByTagName('img')[0] != undefined){
			// image is already existing
			overlayImage.getElementsByTagName('img')[0].src = smallsrc;
			overlayImageImg = overlayImage.getElementsByTagName('img')[0];
		}else{
			// image is not existing
			overlayImageImg = new Image;
			overlayImageImg.src = smallsrc;
			overlayImage.appendChild(overlayImageImg);
			overlayImageImg.addEventListener('click', showNextImageInGallery);
		}
		overlayImageImg.classList.add('js-loading');
		if(isSmaller == 'true'){overlayImageImg.classList.add('smaller')}else{overlayImageImg.classList.remove('smaller')}

		overlayImageTitle.innerHTML = data[2];
		overlayProjectTitle.innerHTML = data[1];
		overlayProjectNumber.innerHTML = data[6];

		if((data[4] / data[5]) > window.innerWidth / window.innerHeight){
			overlayImageImg.classList.add('js-landscape');
			overlayImageImg.classList.remove('js-portrait');
		}else{
			overlayImageImg.classList.add('js-portrait');
			overlayImageImg.classList.remove('js-landscape');
		}

		let newImg = new Image;
		let sourceAtThisTime = overlayImageImg.src;
		newImg.onload = function(e) {
			if(overlayImageImg.src == sourceAtThisTime){
				overlayImageImg.src = data[0];
				overlayImageImg.classList.remove('js-loading');
			};
		}
		newImg.src = data[0];
	}

	function showNextImageInGallery() {
		projectImageInOverlay = overlayNextImage(projectImageInOverlay);
		let data = projectImageInOverlay.getAttribute('data-gallery').split(';;');
		fillOverlay(data, projectImageInOverlay.currentSrc);
	}
}
// show / hide overlay
function showOverlay(bool) {
	if(bool == true){
		overlay.classList.add('js-visible');
		// showHelper('< use keyboard to navigate >', 2000);
	}else{
		overlay.classList.remove('js-visible');
	}
}

// fill the html of the mobile info overlay
function showMobileInfo(data) {
	overlayImageTitle.innerHTML = data[2];
	overlayProjectTitle.innerHTML = data[1];
	overlayProjectNumber.innerHTML = data[6];
	overlay.classList.add('js-visible-mobile');
}
function hideMobileInfo() {
	overlay.classList.remove('js-visible-mobile');
}

['scroll'].forEach( function(evt) {
	window.addEventListener(evt, hideMobileInfo, false);
});


// iterate through project images and add click listener
projectImages.forEach(function (projectImage) {
	let data = projectImage.getAttribute('data-gallery').split(';;');
	projectImage.addEventListener('click', function () {
		if(window.innerWidth >= breakpoint1){
			projectImageInOverlay = projectImage;
			fillOverlay(data, projectImage.currentSrc);
			showOverlay(true);
		}else{
			showMobileInfo(data);
		}

	});
});

overlayBackground.addEventListener('click', function () {
	showOverlay(false);
});

overlayBottomBar.addEventListener('click', function () {
	showOverlay(false);
});

// key gestures for gallery
document.onkeydown = function(e) {
	e = e || window.event;
	switch(e.which || e.keyCode) {
		case 37: // left
			projectImageInOverlay = overlayPrevImage(projectImageInOverlay);
			let dataLeft = projectImageInOverlay.getAttribute('data-gallery').split(';;');
			fillOverlay(dataLeft, projectImageInOverlay.currentSrc);
		break;

		case 39: // right
			projectImageInOverlay = overlayNextImage(projectImageInOverlay);
			let dataRight = projectImageInOverlay.getAttribute('data-gallery').split(';;');
			fillOverlay(dataRight, projectImageInOverlay.currentSrc);
		break;

		case 27: // esc
			showOverlay(false);
		break;

		default: return; // exit this handler for other keys
	}
	e.preventDefault(); // prevent the default action (scroll / move caret)
};
// check image ratio on resize
['resize','orientationchange'].forEach( function(evt) {
	window.addEventListener(evt,
		utils.debounce(function () {
			if(overlayImage.querySelectorAll('img')[0]){
				let overlayImageImg = overlayImage.querySelectorAll('img')[0];
				if((overlayImage.querySelectorAll('img')[0].offsetWidth / overlayImage.querySelectorAll('img')[0].offsetHeight) > window.innerWidth / window.innerHeight){
					overlayImageImg.classList.add('js-landscape');
					overlayImageImg.classList.remove('js-portrait');
				}else{
					overlayImageImg.classList.add('js-portrait');
					overlayImageImg.classList.remove('js-landscape');
				}
			}
		}, 250),
	false);
});

// HELPER
function showHelper(text,duration) {
	if(useKeyboardHelperShown == false){
		useKeyboardHelperShown = true;
		helper.innerHTML = text;
		helper.classList.add('js-visible');
		setTimeout(function () {
			helper.classList.remove('js-visible');
		}, duration);
	}
}

// Add target: _blank to external links
for (let links = document.links, i = 0, a; a = links[i]; i++) {
	addTargetBlankIfExternal(a);
}

 });

// checks if elem1 is higher than elem2
function isHigherThan(elem1, elem2) {
return (elem1.offsetHeight > elem2.offsetHeight);
}

function elementInViewport(el) {
let top = el.offsetTop;
let height = el.offsetHeight;
let bottom = top + height;

while(el.offsetParent) {
	el = el.offsetParent;
	top += el.offsetTop;
	bottom = top + height;
}

return {
	topAfterViewport: window.pageYOffset - top,
	topAfterViewportBottom: window.pageYOffset + window.innerHeight - top,
	bottomAfterViewport: window.pageYOffset - bottom,
};
};

function addTargetBlankIfExternal(a) {
if (a.host !== location.host) {
	a.target = '_blank';
}
}
